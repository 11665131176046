














  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'

  const wishlist = namespace('wishlist')

  @Component({
    components: {
      OneIconLink: () => import('~/components/molecules/navigations/OneIconLink.vue'),
      OneIconLoader: () => import('~/components/molecules/icons/OneIconLoader.vue'),
    },
  })
  export default class WishlistIconComponent extends Vue {
    @wishlist.Getter getWishlistItemsCount: any
    @wishlist.Getter getCurrentWishlistId: any
    @wishlist.Getter getCurrentWishlist: any
    @wishlist.Action addProductToWishlist: any

    @Prop({
      type: String,
      required: true,
    })
    icon!: string;

    get isWishlistAvailable(): boolean {
      return this.$utils.isPurchaseEnabledOrAuthenticated && !this.$auth.passwordChangeIsRequired()
    }

    get currentWishlistName(): string | null {
      return (this.getCurrentWishlist && this.getCurrentWishlist.name) || null
    }

    get itemsInWishlist(): number {
      return this.getCurrentWishlistId ? this.getWishlistItemsCount(this.getCurrentWishlistId) : 0
    }
  }
